import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: { borderRadius: "none" },
  variants: {
    outline: ({ colorScheme }) => ({
      color: "chakra-body-text",
      borderColor: `${colorScheme}.500`,
      borderWidth: "2px",
      "&:hover:not(:disabled)": {
        bg: `${colorScheme}.500`,
        color: "white",
      },
    }),
  },
  defaultProps: { variant: "outline" },
};

export default Button;
