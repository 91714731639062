import jwtDecode, { JwtPayload } from "jwt-decode";
//
import axiosClient from "./axiosClient";

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string): Boolean => {
  try {
    const decoded = jwtDecode<Required<JwtPayload>>(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  } catch (e) {
    return false;
  }
};

export type TokenData = JwtPayload & { type: number };

export function decodeToken(accessToken: string): TokenData {
  return jwtDecode<TokenData>(accessToken);
}

export const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axiosClient.defaults.headers.common.Authorization;
  }
};