import axios from "axios";
import { defaultLocale } from "./i18n";

const API_URL = process.env.REACT_APP_API_BASE_URL as string;
if (!API_URL) throw new Error("REACT_APP_API_BASE_URL env variable is not defined");

const axiosClient = axios.create({ baseURL: API_URL });
axiosClient.defaults.headers.common["Accept-Language"] = defaultLocale;

axiosClient.interceptors.response.use((value) => value.data);

export default axiosClient;
