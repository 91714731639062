import axiosClient from "../utils/axiosClient";

function loadProfile() {
  return axiosClient.get<null, APIResponse<Notary>>("/notary/profile");
}

function updateProfile(data: UpdateNotary) {
  return axiosClient.patch<null, APIResponse<Notary>>("/notary/profile", data);
}

function reschedule({ apptId, date, timezone }: { apptId: number; date: string; timezone: string }) {
  return axiosClient.post<null, { success: boolean }>(`/notary/appointments/${apptId}/reschedule`, { date, timezone });
}

function sendWitnessAppointments(id: number) {
  return axiosClient.post<null, { is_sent: true }>(`notary/appointments/${id}/send-witness-appointments`);
}


function getTransactions(page?: number) {
  return axiosClient.get<null, ResPaginated<Transaction>>("/notary/transactions", { params: { page } });
}

function getClients(page?: number) {
  return axiosClient.get<null, ResPaginated<Client>>("/notary/clients", { params: { page } });
}

function withdraw(amount: number) {
  return axiosClient.post<null, { success: boolean }>("/notary/withdraw", { amount });
}

function show(username: string) {
  return axiosClient.get<null, APIResponse<Notary>>(`/notaries/${username}`);
}

const notaryApi = {
  loadProfile,
  updateProfile,
  getClients,
  reschedule,
  sendWitnessAppointments,
  getTransactions,
  withdraw,
  show,
};

export default notaryApi;
