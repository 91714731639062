import { FC, RefObject, useEffect, useRef } from "react";
import { defineMessage, Trans } from "@lingui/macro";
import type { MessageDescriptor } from "@lingui/core";
import { startCase } from "lodash-es";
import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Image,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  ChevronDownIcon,
  GlobeAltIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link as RouterLink, NavLink, useLocation } from "react-router-dom";
import useConfig from "../hooks/useConfig";
import { dynamicActivate, locales } from "../utils/i18n";
import { buildYupLocale } from "../utils/yup";
import axiosClient from "../utils/axiosClient";
import logo from "../assets/images/logo.svg";
import profileImg from "../assets/icons/profile.png";
import useAuth from "../hooks/useAuth";
import { AccountType } from "../contexts/JWTContext";
import ProgressBar from "./ProgressBar";
import useUserQuery from "../hooks/useUserQuery";
import {isClient, isNotary} from "../utils/typeGuards";

interface NavItem {
  title: MessageDescriptor;
  path: string;
  children?: NavItem[];
}

const NAV_ITEMS: NavItem[] = [
  { title: defineMessage({ id: "home" }), path: "/" },
  { title: defineMessage({ id: "request a service" }), path: "/request-service" },
  { title: defineMessage({ id: "about us" }), path: "/about" },
  { title: defineMessage({ id: "contact us" }), path: "/contact-us" },
  { title: defineMessage({ id: "verification" }), path: "/verify" },
  {
    title: defineMessage({ id: "Resources" }),
    path: "/resources",
    children: [
      { title: defineMessage({ id: "Find a Document" }), path: "https://blog.notaryspace.ca/find-a-document/" },
      { title: defineMessage({ id: "Blog" }), path: "https://blog.notaryspace.ca/" },
      { title: defineMessage({ id: "FAQ" }), path: "https://blog.notaryspace.ca/faq/" },
      { title: defineMessage({ id: "Help" }), path: "https://blog.notaryspace.ca/help/" },
    ],
  },
];

interface Props {}

const Header: FC<Props> = () => {
  const { onChange, direction, locale } = useConfig();
  const { isAuthenticated, accountType } = useAuth();
  const userData = useUserQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const [isLoading, setLoading] = useBoolean();

  function onLocaleChange(locale: string) {
    setLoading.on();
    axiosClient.defaults.headers.common["Accept-Language"] = locale;
    localStorage.setItem("lang", locale);
    dynamicActivate(locale).then(() => {
      setLoading.off();
      buildYupLocale();
      onChange({ locale });
    });
  }

  return (
    <Box as="header" py="5" shadow="lg" pos="relative" zIndex="10">
      <Container display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse">
        <Link as={RouterLink} to="/">
          <Image src={logo} alt="Notary Space" height={{ base: 10, lg: 12 }} />
        </Link>
        <Box display="flex" alignItems="center">
          <IconButton
            aria-label="Navigation"
            icon={<Icon as={direction === "rtl" ? Bars3BottomRightIcon : Bars3BottomLeftIcon} boxSize="6" />}
            color="gray.700"
            variant="ghost"
            ref={btnRef}
            onClick={onOpen}
            me="5"
          />
          <Stack
            as="ul"
            listStyleType="none"
            direction="row"
            spacing="5"
            display={{ base: "none", lg: "flex" }}
            alignItems="center"
            sx={{
              ".chakra-link": {
                textTransform: "capitalize",
                _hover: { color: "brand.500", textDecor: "none" },
                "&.active": { color: "brand.500", fontWeight: "semibold" },
              },
            }}
          >
            {NAV_ITEMS.map(({ title, path, children }, i) => {
              if (accountType === AccountType.notary && path === "/request-service") return null;
              if (children) {
                return (
                  <Menu key={i}>
                    <MenuButton
                      as={Button}
                      rightIcon={<Icon as={ChevronDownIcon} />}
                      variant="link"
                      fontWeight="normal"
                      textTransform="capitalize"
                      color="chakra-body-text"
                      _hover={{ textDeoc: "none", color: "brand.500" }}
                    >
                      <Trans id={title.id} />
                    </MenuButton>
                    <MenuList>
                      {children.map(({ path, title }, i) => {
                        if (path.startsWith("http")) {
                          return (
                            <MenuItem key={i} as={Link} href={path}>
                              <Trans id={title.id} />
                            </MenuItem>
                          );
                        }
                        return (
                          <MenuItem key={i} as={NavLink} to={path}>
                            <Trans id={title.id} />
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                );
              }
              return (
                <Box as="li" key={i}>
                  <Link as={NavLink} to={path}>
                    <Trans id={title.id} />
                  </Link>
                </Box>
              );
            })}
            {!isAuthenticated && (
              <Box as="li">
                <Link as={NavLink} to="/login">
                  <Trans>Sign In</Trans>
                </Link>
              </Box>
            )}
            {isAuthenticated && isClient(userData.data) && (
              <Box as="li">
                <Link as={NavLink} to={`/${AccountType[accountType as number]}/account?tab=meetings`}>
                  <Trans>
                    My meetings
                  </Trans>
                </Link>
              </Box>
            )}
            {isAuthenticated && isNotary(userData.data) && (
              <Box as="li">
                <Link as={NavLink} to={`/${AccountType[accountType as number]}`}>
                  {userData.data.fullname}
                </Link>
              </Box>
            )}
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<Icon as={ChevronDownIcon} />}
                variant="link"
                fontWeight="normal"
                textTransform="capitalize"
                color="chakra-body-text"
                _hover={{ textDeoc: "none", color: "brand.500" }}
              >
                {locales.find(({ slug }) => slug === locale)?.name}
              </MenuButton>
              <MenuList>
                {locales.map(({ name, slug }, i) => (
                  <MenuItem key={i} value={slug} onClick={() => onLocaleChange(slug)}>
                    {name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Stack>
        </Box>
      </Container>
      <MobileDrawer
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        onLocaleChange={onLocaleChange}
        btnRef={btnRef}
      />
    </Box>
  );
};

interface MobileDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onLocaleChange: (locale: string) => void;
  btnRef?: RefObject<HTMLElement>;
}

const MobileDrawer: FC<MobileDrawerProps> = ({ onClose, isOpen, isLoading, onLocaleChange, btnRef }) => {
  const { direction, locale } = useConfig();
  const { isAuthenticated, accountType, onLogout } = useAuth();
  const userData = useUserQuery();

  const { pathname } = useLocation();
  useEffect(() => {
    // close the drawer when the route changes
    onClose();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && <ProgressBar />}
      <Drawer
        isOpen={isOpen}
        placement={direction === "rtl" ? "right" : "left"}
        size="sm"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody display="flex" flexDir="column" alignItems="flex-start" color="white" bgColor="dark">
            <IconButton
              aria-label="Close"
              onClick={onClose}
              icon={<Icon as={XMarkIcon} boxSize={9} />}
              variant="link"
              ms="-2.5"
              py="2"
              mt="6"
            />
            <Stack
              as="ul"
              spacing="4"
              mt="5"
              mb="6"
              listStyleType="none"
              sx={{
                ".chakra-link, .chakra-button": {
                  textTransform: "capitalize",
                  fontSize: "lg",
                  "&.active": { textDecor: "underline" },
                },
              }}
            >
              {NAV_ITEMS.map(({ title, path, children }, i) => {
                if (accountType === AccountType.notary && path.startsWith("/client")) return null;
                if (children) return null;
                return (
                  <Box as="li" key={i}>
                    <Link as={NavLink} to={path}>
                      <Trans id={title.id} />
                    </Link>
                  </Box>
                );
              })}
              {!isAuthenticated && (
                <>
                  <Box as="li">
                    <Link as={NavLink} to="/login">
                      <Trans>Sign In</Trans>
                    </Link>
                  </Box>
                  <Box as="li">
                    <Link as={NavLink} to="/register">
                      <Trans>Register</Trans>
                    </Link>
                  </Box>
                  <Box as="li">
                    <Link as={NavLink} to="/join">
                      <Trans>Join Our Team</Trans>
                    </Link>
                  </Box>
                </>
              )}
              {isAuthenticated && (
                <Box as="li">
                  <Button
                    variant="link"
                    className="chakra-link"
                    fontWeight="normal"
                    color="inherit"
                    onClick={() => {
                      onLogout();
                      onClose();
                    }}
                  >
                    <Trans>Logout</Trans>
                  </Button>
                </Box>
              )}
            </Stack>
            {isAuthenticated && userData.data && (
              <HStack spacing="2.5" as={RouterLink} to={`/${AccountType[accountType as number]}`} mb="6">
                <Image
                  src={userData.data.image || profileImg}
                  alt={userData.data.fullname}
                  boxSize="16"
                  objectFit="cover"
                  objectPosition="center"
                  rounded="full"
                  borderWidth="2px"
                  borderStyle="solid"
                  borderColor="brand.500"
                />
                <Box>
                  <Text mb=".5">{startCase(userData.data.fullname)}</Text>
                  <Text fontSize="sm" color="gray.100">
                    {userData.data.email}
                  </Text>
                </Box>
              </HStack>
            )}
            <InputGroup variant="outline" sx={{ ".chakra-select": { ps: 9 } }} mt="auto" mb="4">
              <InputLeftElement
                pointerEvents="none"
                left="0"
                children={<Icon as={GlobeAltIcon} color="brand.500" boxSize="5" />}
              />
              <Select
                isDisabled={isLoading}
                value={locale}
                onChange={({ currentTarget }) => onLocaleChange(currentTarget.value)}
                sx={{ option: { color: "chakra-body-text" } }}
              >
                {locales.map(({ name, slug }, i) => (
                  <option key={i} value={slug}>
                    {name}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
