import axiosClient from "../utils/axiosClient";

function all() {
  return axiosClient.get<null, APIResponse<PageData[]>>("/pages");
}

function show(slug: string) {
  return axiosClient.get<null, APIResponse<Required<PageData>>>(`/pages/${slug}`);
}

const pageApi = { all, show };

export default pageApi;
