import axiosClient from "../utils/axiosClient";

function loadProfile() {
  return axiosClient.get<null, APIResponse<Client>>("/client/profile");
}

function updateProfile(data: UpdateClient) {
  return axiosClient.patch<null, APIResponse<Client>>("/client/profile", data);
}

function verifyIdentity() {
  return axiosClient.post<null, { client_secret: string }>("/client/verify-identity");
}

function getDocuments(page: number) {
  return axiosClient.get<null, ResPaginated<Document>>("/client/documents", { params: { page } });
}

const clientApi = { loadProfile, updateProfile, getDocuments, verifyIdentity };

export default clientApi;
