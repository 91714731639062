import { ComponentStyleConfig } from "@chakra-ui/react";

const Textarea: ComponentStyleConfig = {
  sizes: {
    md: { borderRadius: "none" },
    lg: { borderRadius: "none" },
  },
  variants: { outline: { borderColor: "gray.300" } },
};

export default Textarea;
