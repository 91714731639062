import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AccountType } from "../contexts/JWTContext";

interface Props {
  children?: JSX.Element;
  accountType: AccountType;
}

export default function RequireAuth({ children, accountType }: Props) {
  const { accountType: currAccountType, isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  if (isAuthenticated && currAccountType === accountType) {
    if (children) return children;
    return <Outlet />;
  }

  // user tried to access a page that requires a different account type
  if (isAuthenticated) return <Navigate to={`/${AccountType[currAccountType as number]}/account`} replace />;
  // user is not authenticated
  return <Navigate to="/login" state={{ from: pathname }} replace />;
}
