import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Input: ComponentMultiStyleConfig = {
  parts: parts.keys,
  variants: {
    outline: {
      field: { borderColor: "gray.300" },
    },
  },
  sizes: {
    md: { field: { borderRadius: "none" } },
    lg: { field: { borderRadius: "none" } },
  },
};

export default Input;
