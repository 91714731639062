import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useUserQuery from "../hooks/useUserQuery";
import { isClient } from "../utils/typeGuards";

interface Props {
  children: JSX.Element;
}

const requiredFields = ["phone", "address", "occupation", "postal_code"] as const;

export default function RequireCompletedAccountSetup({ children }: Props) {
  const userData = useUserQuery();

  if (isClient(userData.data)) {
    const missingFields = requiredFields.filter((field) => !userData.data?.[field]);

    if (missingFields.length > 0) {
      const missingFieldsKeys = missingFields.join(",");
      const url = `/client/account?missing=${missingFieldsKeys}`;
      return <Navigate to={url} replace />;
    }
  }

  return children;
}
