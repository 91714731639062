import axiosClient from "../utils/axiosClient";

function all() {
  return axiosClient.get<null, APIResponse<SiteKey[]>>("/sitekeys");
}

const sitekeyApi = { all };

export default sitekeyApi;

