import { ThemeOverride } from "@chakra-ui/react";
import "intl-tel-input/build/css/intlTelInput.css";

const styles: ThemeOverride["styles"] = {
  global: ({ theme: { direction } }) => {
    const isRtl = direction === "rtl";
    return {
      html: { direction },
      ".iti": { width: "100%", display: "inline-flex" },
      ".iti.iti--allow-dropdown .iti__flag-container": {
        insetEnd: 0,
        insetStart: "auto",
      },
      ".iti.iti--allow-dropdown input[type=tel]": {
        direction: "ltr",
        textAlign: isRtl ? "right" : "left",
        pl: isRtl ? "84px !important" : "var(--chakra-sizes-4) !important",
        pr: isRtl ? "var(--chakra-sizes-4) !important" : "84px !important",
      },
      ".iti__selected-flag": {
        pe: 3,
        gap: 1,
        flexDirection: "row-reverse",
        fontSize: "sm",
        color: "gray.600",
        userSelect: "none",
        transitionProperty: "colors",
        transitionDuration: "normal",
        "&:not(:hover)": {
          bgColor: "transparent !important",
        },
      },
      ".iti__selected-dial-code": {
        direction: "ltr",
      },
      ".iti__selected-dial-code, .iti__arrow": {
        ml: "0 !important",
      },
      ".iti__arrow": {
        boxSize: 2,
        border: 0,
        backgroundSize: "contain",
        backgroundImage:
          'url(\'data:image/svg+xml,%3Csvg fill="rgba(0, 0, 0, 0.7)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath d="M2.813 4.594l9.188 9.188 9.188-9.188 2.813 2.813-12 12-12-12z"/%3E%3C/svg%3E\')',
        "&.iti__arrow--up": { transform: "rotate(180deg)" },
      },
      ".iti .iti__country-list": {
        direction: "ltr",
        textAlign: isRtl ? "right" : "left",
        insetEnd: 0,
        zIndex: 10,
      },
      ".iti-mobile .iti--container": {
        zIndex: 9999,
        insetStart: "10px",
        width: "calc(100% - 20px)",
      },
      "#nprogress": {
        "& .bar": {
          top: 0,
          left: 0,
          height: 1,
          width: "100%",
          position: "fixed",
          zIndex: 2000,
          backgroundColor: "brand.500",
          boxShadow: `0 0 2px var(--chakra-colors-brand-400)`,
        },
        "& .peg": {
          right: 0,
          opacity: 1,
          width: 100,
          height: "100%",
          display: "block",
          position: "absolute",
          transform: "rotate(3deg) translate(0px, -4px)",
          boxShadow: `0 0 10px var(--chakra-colors-brand-500), 0 0 5px var(--chakra-colors-brand-500)`,
        },
      },
    };
  },
};

export default styles;