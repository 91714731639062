import { Trans } from "@lingui/macro";
import { Box, Container, Heading, Image } from "@chakra-ui/react";
import Page from "../components/Page";
import writing from "../assets/icons/writing.png";

export default function Page404() {
  return (
    <Page title="404">
      <Container
        height="calc(100vh - 80px)"
        maxW="container.lg"
        py="20"
        textAlign="center"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={writing} alt="Book" htmlWidth="512" htmlHeight="512" w="256px" mb="10" />
        <Heading fontSize="lg">
          <Box as="span" me="3" fontSize="3xl" verticalAlign="middle">
            404
          </Box>
          <Box as="span" verticalAlign="middle" fontWeight="semibold">
            <Trans>The page you are looking for was not found.</Trans>
          </Box>
        </Heading>
      </Container>
    </Page>
  );
}