import { createContext, ReactNode, useState } from "react";
import { defaultLocale } from "../utils/i18n";

interface State {
  locale: string;
}

const initialState: State = {
  locale: defaultLocale,
};

interface Context extends State {
  direction: "rtl" | "ltr";
  onChange: (state: Partial<State>) => void;
}

export const ConfigContext = createContext<Context>({
  ...initialState,
  direction: initialState.locale === "ar" ? "rtl" : "ltr",
  onChange: () => {},
});

export default function ConfigProvider({ children }: { children: ReactNode }) {
  const [config, setConfig] = useState<State>(initialState);

  function onChange(state: Partial<State>) {
    setConfig((prevState) => Object.assign({}, prevState, state));
  }

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChange,
        direction: config.locale === "ar" ? "rtl" : "ltr",
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}