import { ComponentStyleConfig } from "@chakra-ui/react";

const Select: ComponentStyleConfig = {
  variants: { outline: { field: { borderColor: "gray.300" } } },
  sizes: {
    md: { field: { borderRadius: "none" } },
    lg: { field: { borderRadius: "none" } },
  },
};

export default Select;
