import { ThemeComponents } from "@chakra-ui/react";
import Container from "./Container";
import Input from "./Input";
import Button from "./Button";
import Heading from "./Heading";
import Textarea from "./Textarea";
import Select from "./Select";
import Menu from "./Menu";
import Modal from "./modal";

const components: ThemeComponents = { Container, Input, Button, Heading, Textarea, Select, Menu, Modal };
export default components;