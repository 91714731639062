import { t } from "@lingui/macro";
import { setLocale } from "yup";

// Note: below I'm escaping lingui placeholder using '''
// see: https://github.com/lingui/js-lingui/issues/421

export function buildYupLocale() {
  setLocale({
    mixed: {
      required: t`$'{path}' is a required field`,
      default: t`$'{path}' is invalid`,
      notType: t`$'{path}' must be a valid number`,
    },
    string: {
      email: t`$'{path}' must be a valid email address`,
      min: t`$'{path}' must be at least $'{min}' characters`,
      max: t`$'{path}' must be at most $'{max}' characters`,
      url: t`$'{path}' must be a valid URL`,
    },
  });
}