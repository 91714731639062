import { lazy, Suspense, useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import Page404 from "./pages/Page404";
import Layout from "./components/Layout";
import { AccountType } from "./contexts/JWTContext";
import RequireAuth from "./components/RequireAuth";
import useConfig from "./hooks/useConfig";
import pageApi from "./api/pageApi";
import RequireCompletedAccountSetup from "./components/RequireCompletedAccountSetup";
import ErrorBoundary from "./components/ErrorBoundary";

// pages
const NotaryAccount = lazy(() => import("./pages/NotaryAccount"));
const ClientAccount = lazy(() => import("./pages/ClientAccount"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const JoinPage = lazy(() => import("./pages/JoinPage"));
const VideoChat = lazy(() => import("./pages/VideoChat"));
const WitnessPage = lazy(() => import("./pages/WitnessPage"));
const ApptDetails = lazy(() => import("./pages/ApptDetails"));
const PolicyPage = lazy(() => import("./pages/PolicyPage"));
const ServicePage = lazy(() => import("./pages/ServicePage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const Verify = lazy(() => import("./pages/Verify"));
const RequestService = lazy(() => import("./pages/RequestServicePage"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const ServiceDetails = lazy(() => import("./pages/ServiceDetails"));
const PublicProfile = lazy(() => import("./pages/PublicProfile"));
const SuccessRequest = lazy(() => import("./pages/SuccessRequest"));
const ResetPage = lazy(() => import("./pages/ResetPage"));

export default function App() {
  const { locale } = useConfig();
  const queryClient = useQueryClient();
  useEffect(() => {
    // prefetch data on mount
    queryClient.prefetchQuery(["pages", { locale: locale }], pageApi.all);
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route
        path="/video-chat"
        element={
          <Suspense>
            <VideoChat />
          </Suspense>
        }
      />
      <Route
        path="/witness"
        element={
          <Suspense>
            <WitnessPage />
          </Suspense>
        }
      />
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          }
        />
        <Route
          path="about"
          element={
            <ErrorBoundary>
              <About />
            </ErrorBoundary>
          }
        />
        <Route
          path="pages/:slug"
          element={
            <ErrorBoundary>
              <PolicyPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="services/:id"
          element={
            <ErrorBoundary>
              <ServicePage />
            </ErrorBoundary>
          }
        />
        <Route
          path="online-notary-details"
          element={
            <ErrorBoundary>
              <ServiceDetails />
            </ErrorBoundary>
          }
        />
        <Route
          path="verify"
          element={
            <ErrorBoundary>
              <Verify />
            </ErrorBoundary>
          }
        />
        <Route
          path="login"
          element={
            <ErrorBoundary>
              <LoginPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="register"
          element={
            <ErrorBoundary>
              <RegisterPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="forgot-password"
          element={
            <ErrorBoundary>
              <ResetPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="join"
          element={
            <ErrorBoundary>
              <JoinPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="notary"
          element={
            <RequireAuth accountType={AccountType.notary}>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to="/notary/account" />} />
          <Route
            path="account"
            element={
              <ErrorBoundary>
                <NotaryAccount />
              </ErrorBoundary>
            }
          />
          <Route
            path="meetings/:id"
            element={
              <ErrorBoundary>
                <ApptDetails />
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="request-service"
          element={
            <ErrorBoundary>
              <RequireAuth accountType={AccountType.client}>
                <RequireCompletedAccountSetup>
                  <RequestService />
                </RequireCompletedAccountSetup>
              </RequireAuth>
            </ErrorBoundary>
          }
        />
        <Route
          path="client"
          element={
            <RequireAuth accountType={AccountType.client}>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to="/client/account" />} />
          <Route
            path="meetings/:id"
            element={
              <ErrorBoundary>
                <ApptDetails />
              </ErrorBoundary>
            }
          />
          <Route
            path="request-service/success"
            element={
              <ErrorBoundary>
                <SuccessRequest />
              </ErrorBoundary>
            }
          />
          <Route
            path="account"
            element={
              <ErrorBoundary>
                <ClientAccount />
              </ErrorBoundary>
            }
          />
        </Route>
        <Route
          path="contact-us"
          element={
            <ErrorBoundary>
              <ContactUsPage />
            </ErrorBoundary>
          }
        />
        <Route
          path="notaries/:username"
          element={
            <ErrorBoundary>
              <PublicProfile />
            </ErrorBoundary>
          }
        />
        <Route
          path="404"
          element={
            <ErrorBoundary>
              <Page404 />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
}
