import { Container, ContainerProps, Heading, Text } from "@chakra-ui/react";
import { Component } from "react";
import { Trans } from "@lingui/macro";

class ErrorBoundary extends Component<ContainerProps> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    const { children, ...props } = this.props;
    if (this.state.hasError) {
      return (
        <Container py="12" maxW="container.lg" {...props}>
          <Heading as="h3" size="lg" color="red.500" mb="3">
            <Trans>Something went wrong</Trans>
          </Heading>

          <Text fontSize="lg">
            <Trans>
              Something went wrong, if you are a site admin you can see a detailed error message in the console and if
              you are not please contact the site admins and tell them what happened.
            </Trans>
            <br />
            <Trans>By the way, screenshots are very helpful ;).</Trans>
          </Text>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
