import { i18n } from "@lingui/core";
import { ar, en } from "make-plural/plurals";
import { detect, fromStorage } from "@lingui/detect-locale";

export const locales = [
  { name: "العربية", slug: "ar" },
  { name: "English", slug: "en" },
];
export const defaultLocale = detect(fromStorage("lang"), "en") as string;

i18n.loadLocaleData({
  en: { plurals: en },
  ar: { plurals: ar },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`/src/locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}