import { ReactNode, useMemo } from "react";
import { ChakraProvider, extendTheme, ThemeOverride, withDefaultColorScheme } from "@chakra-ui/react";
import useConfig from "../hooks/useConfig";
import components from "./components";
import styles from "./styles";
import colors from "./colors";

const fonts: ThemeOverride["fonts"] = {
  heading: `Cairo, system-ui, sans-serif`,
  body: `Cairo, system-ui, sans-serif`,
};

const shadows = {
  sm: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
  lg: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
};

export default function ThemeProvider(props: { children: ReactNode }) {
  const { direction } = useConfig();
  const theme = useMemo(
    () =>
      extendTheme(
        {
          fonts: fonts,
          styles,
          shadows,
          direction,
          colors,
          components,
        },
        withDefaultColorScheme({ colorScheme: "brand" })
      ),
    [direction]
  );

  return (
    <ChakraProvider theme={theme} resetCSS>
      {props.children}
    </ChakraProvider>
  );
}