import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";
import ProgressBar from "./ProgressBar";
import ScrollToTop from "./ScrollToTop";

export default function Layout() {
  return (
    <>
      <ScrollToTop />
      <Box minHeight="100vh">
        <Header />
        <Box as="main">
          <Suspense fallback={<ProgressBar />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
      <Footer />
    </>
  );
}