import { t } from "@lingui/macro";
import { ReactNode, useCallback, useMemo } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useAuth from "../hooks/useAuth";
import useAlert from "../hooks/useAlert";
import useConfig from "../hooks/useConfig";

export default function QueryProvider({ children }: { children: ReactNode }) {
  const { locale } = useConfig();
  const { onLogout } = useAuth();
  const alert = useAlert();

  const handleQueryError = useCallback(
    (error: unknown) => {
      let msg = t`Unexpected Error`;
      if (error instanceof AxiosError) {
        const { response, request } = error;
        if (response?.data.message) msg = response.data.message;
        if (request.status === 401) {
          onLogout();
          msg = t`You need to login to continue`;
        } else if (request.status === 0) msg = t`Can not reach server.`;
      }
      else if (error instanceof Error) {
        msg = error.message;
      }
      alert({ title: t`Error`, description: msg });
    },
    [onLogout, alert, locale] // eslint-disable-line
  );

  const handleMutationError = useCallback(
    (error: unknown) => {
      if (error instanceof AxiosError) {
        const errors = error.response?.data?.errors;
        if (typeof errors !== "object") return handleQueryError(error);

        Object.keys(errors).forEach((key: any) => {
          const error = Array.isArray(errors[key]) ? errors[key].join(", ") : errors[key];
          alert({ title: key, description: error });
        });
      } else handleQueryError(error)
    },
    [handleQueryError, alert]
  );

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
      queryCache: new QueryCache({ onError: handleQueryError as any }),
      mutationCache: new MutationCache({ onError: handleMutationError as any }),
    });
  }, []); // eslint-disable-line

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
