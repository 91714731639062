import { useCallback } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  ToastProps,
  useToast,
} from "@chakra-ui/react";

/**
 * Chakra ui toast custom component to match the design
 */
export default function useAlert(): (options: ToastProps) => void {
  const toast = useToast();

  return useCallback(
    ({ title, description, variant, status = "error", position = "bottom-left", duration = 5000 }) => {
      toast({
        duration,
        position,
        render: ({ onClose }) => (
          <Alert status={status} variant={variant} pe="7">
            <AlertIcon />
            <Box>
              <AlertTitle textTransform="capitalize">{title}</AlertTitle>
              <AlertDescription>{description}</AlertDescription>
            </Box>
            <CloseButton onClick={onClose} size="sm" pos="absolute" insetEnd="2" top="2" />
          </Alert>
        ),
      });
    },
    [toast]
  );
}
