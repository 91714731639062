import { FC } from "react";
import { Box, BoxProps, Image } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import logo from "../assets/images/logo.svg";

const fade = keyframes`
  0% {
    opacity: .1
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: .1
  }
`;

const LoadingLogo: FC<BoxProps> = (props) => {
  return (
    <Box height="calc(100vh - 16px)" px="10" display="flex" alignItems="center" justifyContent="center" {...props}>
      <Image src={logo} alt="Notary Space" width="280px" maxW="100%" sx={{ animation: `${fade} infinite 1.5s` }} />
    </Box>
  );
};

export default LoadingLogo;
