import { ThemeOverride } from "@chakra-ui/react";

const colors: ThemeOverride["colors"] = {
  dark: "#262626",
  brand: {
    "50": "#fef3f2",
    "100": "#ffcdc9",
    "200": "#f2493c",
    "300": "#e83f32",
    "400": "#de3528",
    "500": "#d42b1e",
    "600": "#ca2114",
    "700": "#c0170a",
    "800": "#b60d00",
    "900": "#ac0300",
  },
};

export default colors;