import { useEffect } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { startCase } from "lodash-es";

interface PageProps extends BoxProps {
  title?: string;
}

export default function Page({ children, title, ...rest }: PageProps) {
  useEffect(() => {
    if (title) document.title = title === "Home" ? "Notary Space" : "Notary Space - " + startCase(title);
  }, [title]);

  return <Box {...rest}>{children}</Box>;
}