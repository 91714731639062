import useAuth from "./useAuth";
import { useQuery } from "@tanstack/react-query";
import { AccountType } from "../contexts/JWTContext";
import clientApi from "../api/clientApi";
import notaryApi from "../api/notaryApi";

function getLSUser(): Notary | Client | null {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
}

export default function useUserQuery() {
  const { accountType, userId } = useAuth();
  const api = accountType === AccountType.client ? clientApi : notaryApi;
  return useQuery(["user", userId], () => api.loadProfile(), {
    enabled: !!userId,
    refetchInterval: 60000,
    select: (data) => data?.data,
    initialData: () => {
      const storedUser = getLSUser();
      return storedUser ? { data: storedUser } : undefined;
    },
    onSuccess(data) {
      if (data) localStorage.setItem("user", JSON.stringify(data));
    },
    onError() {
      localStorage.removeItem("user");
    },
  });
}