import { createContext, ReactNode, useCallback, useEffect, useReducer } from "react";
import { decodeToken, isValidToken, setSession } from "../utils/jwt";
import LoadingLogo from "../components/LoadingLogo";

function getLSUser(): Notary | Client | null {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
}

export enum AccountType {
  notary = 2,
  client,
}

interface State {
  isAuthenticated: boolean;
  isInitialized: boolean;
  accountType: AccountType | null;
  userId: number | null;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  accountType: null,
  userId: null,
};

enum Types {
  init,
  authenticate,
  update,
  logout,
}

interface Action<T = any> {
  type: Types;
  payload: T;
}

function reducer(state: State, { type, payload }: Action): State {
  switch (type) {
    case Types.init:
      return {
        ...state,
        isInitialized: true,
        ...payload,
      };
    case Types.authenticate:
      return {
        ...state,
        isAuthenticated: true,
        ...payload,
      };
    case Types.logout:
      return {
        ...state,
        userId: null,
        isAuthenticated: false,
        accountType: null,
      };
    default:
      throw new Error("Invalid action type was provided");
  }
}

interface Context extends State {
  onInit: (data?: { accountType: number; userId: number }) => void;
  onAuth: (token: string) => void;
  onLogout: () => void;
}

export const AuthContext = createContext<Context>({
  ...initialState,
  // default value for functions
  onInit: () => {},
  onAuth: () => {},
  onLogout: () => {},
});

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // initialize auth
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && isValidToken(accessToken)) initialWork(accessToken);
    else onInit();

    async function initialWork(accessToken: string) {
      const tokenData = decodeToken(accessToken);
      // incase user data in locale storage
      if (tokenData && tokenData.type && tokenData.sub) {
        setSession(accessToken);
        onInit({ accountType: tokenData.type, userId: parseInt(tokenData.sub) });
      } else onInit();
    }
  }, []); // eslint-disable-line

  const onAuth = useCallback<Context["onAuth"]>((token) => {
    const tokenData = decodeToken(token);
    setSession(token);
    dispatch({
      type: Types.authenticate,
      payload: { accountType: tokenData.type, userId: parseInt(tokenData.sub as string) },
    });
  }, []);

  const onInit = useCallback<Context["onInit"]>((data) => {
    dispatch({
      type: Types.init,
      payload: { isAuthenticated: !!data, ...data },
    });
  }, []);

  const onLogout = useCallback(() => {
    setSession(null);
    localStorage.removeItem("user");
    dispatch({ type: Types.logout, payload: null });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        onInit,
        onAuth,
        onLogout,
      }}
    >
      {state.isInitialized ? children : <LoadingLogo />}
    </AuthContext.Provider>
  );
}
